import { Controller } from "stimulus";

const URL_REGEX = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export default class extends Controller {
	static targets = ["submitBtn"];

	handleChange = e => {
		if (URL_REGEX.test(e.target.value)) {
			e.currentTarget.classList.remove("is-invalid");
			this.submitBtnTarget.disabled = false;
		} else {
			e.currentTarget.classList.add("is-invalid");
			this.submitBtnTarget.disabled = true;
		}
	};
}
