const classToggle = (labels) => {
  labels.forEach((label) => {
    label.addEventListener("click", (e) => {
      labels.forEach(l => l.classList.remove('active'))
      e.currentTarget.classList.add("active");
    });
  });
};



const initProfileForm = () => {
  const requirementsLabels = document.querySelectorAll(".job-type-choices label");
  classToggle(requirementsLabels)
};

export { initProfileForm };
