import { Controller } from "stimulus";

export default class extends Controller {
	static targets = [
		"modelFields",
		"numberField",
		"durationField",
		"fieldsWrapper"
	];

	toggleModelFields = () => {
		const modelField = this.modelFieldsTarget.querySelector(".d-none");
		if (modelField) {
			modelField.classList.remove("d-none");
		}
	};

	toggleFields = e => {
		if (e.target.value === "photography") {
			this.numberFieldTarget.classList.remove("d-none");
			this.durationFieldTarget.classList.add("d-none");
      this.numberFieldTarget.querySelector("label").innerText = "Number of Pictures"
      this.numberFieldTarget.querySelector("input").value = ""
		} else if (e.target.value === "videography") {
			this.numberFieldTarget.classList.remove("d-none");
			this.durationFieldTarget.classList.remove("d-none");
      this.numberFieldTarget.querySelector("label").innerText = "Number of Videos"
      this.numberFieldTarget.querySelector("input").value = ""
		} else {
		}
	};
}
