import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["totalhour"];

  handleChange() {
    const elements = document.querySelectorAll(".crew-costs");
    const totalhour = this.totalhourTarget.value;
    elements.forEach((element) => {
      element["bill--subtotaler"].changeHour(totalhour);
    });
  }
}
