const URL_REGEX = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');
const INVALID_URL_SPAN = "<span class=\"error-span\">Invalid URL</span>"

const classToggle = (labels) => {
  labels.forEach((label) => {
    label.addEventListener("click", (e) => {
      e.currentTarget.classList.toggle("active");
    });
  });
};

const toggleInput = (checkBox) => {
  checkBox?.addEventListener("click", (e) => {
    const displayForm = e.currentTarget.classList.contains("active")
      ? "block"
      : "none";
    e.currentTarget.nextElementSibling.style.display = displayForm;
    if (displayForm == "none"){
      e.currentTarget.nextElementSibling.value = '';
    }
  });
};

const checkIsValid = (value, node) => {
  const isValid = URL_REGEX.test(value);
  const container = node.parentElement;
  if (!isValid) {
    container.classList.add("red-border");
    node.classList.add("error")
    if (!container.querySelector(".error-span")) {
      container.insertAdjacentHTML("beforeend", INVALID_URL_SPAN);
    }
  } else {
    container.classList.remove("red-border");
    container.querySelector(".error-span")?.remove();
    node.classList.remove("error")
  }
}

const checkUrl = () => {
  const websiteUrl = document.getElementById("campaign_website_url");
  const referenceUrl = document.getElementById("campaign_reference_url");
  if (websiteUrl) {
    websiteUrl.addEventListener("keyup", e => checkIsValid(e.target.value, e.currentTarget))
  }
  if (referenceUrl) {
    referenceUrl.addEventListener("keyup", e => checkIsValid(e.target.value, e.currentTarget))
  }
}

const initCampaignForm = () => {
  const requirementsLabels = document.querySelectorAll(".extra-requirement");
  const platformLabels = document.querySelectorAll(".campaign-platform");
  const campaignLocations = document.querySelectorAll(".campaign-location");
  [requirementsLabels, platformLabels, campaignLocations].forEach(classToggle);
  const extraLocation = document.querySelector("#extraLocation");
  const extraPlatform = document.querySelector("#extraPlatform");
  checkUrl();
  [extraLocation, extraPlatform].forEach(toggleInput);
};

export { initCampaignForm };
