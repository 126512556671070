// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "consultancyCost",
    "preProductionCost",
    "studioDiscount",
    "halfdayCost",
    "extraAfterHalfdayCost",
    "dailyCost",
    "hourlyCost",
    "productionCost",
    "modelCost",
    "photographerCost",
    "videographerCost",
    "stylistCost",
    "runnerCost",
    "makeupArtistCost",
    "travelBudgetCost",
    "postPhotographyCost",
    "postVideographyCost",
    "finalBill",
    "depositAmount",
    "depositRate",
  ];

  calculate() {
    this.productionCostTarget.value =
      +this.halfdayCostTarget.value +
      +this.extraAfterHalfdayCostTarget.value +
      +this.dailyCostTarget.value +
      +this.hourlyCostTarget.value;

    const operationalComponent = +this.productionCostTarget.value;

    const nonTimeComponent =
      +this.consultancyCostTarget.value +
      +this.preProductionCostTarget.value -
      +this.studioDiscountTarget.value;

    const operationalCost =
      +this.modelCostTarget.value +
      +this.photographerCostTarget.value +
      +this.videographerCostTarget.value +
      +this.stylistCostTarget.value +
      +this.runnerCostTarget.value +
      +this.makeupArtistCostTarget.value +
      +this.travelBudgetCostTarget.value;

    const postProductionCost =
      +this.postPhotographyCostTarget.value +
      +this.postVideographyCostTarget.value;

    this.finalBillTarget.value =
      Math.round(
        (operationalComponent +
          nonTimeComponent +
          operationalCost +
          postProductionCost) *
          100
      ) / 100;

    this.depositAmountTarget.value =
      Math.round(
        this.finalBillTarget.value * +this.depositRateTarget.value * 100
      ) / 100;
  }
}
