import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["preview"];

	connect() {
		this.initialSrc = this.previewTarget.src;
	}

	handleChange = e => {
		const files = e.currentTarget.files;

		if (files.length && files[0]) {
			const reader = new FileReader();
			reader.onload = evt => {
				this.previewTarget.src = evt.currentTarget.result;
			};
			reader.readAsDataURL(files[0]);
		}
	};

	resetSrc = () => {
		this.previewTarget.src = this.initialSrc;
	};
}
