import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "needStylist",
    "vidLength",
    "numPhotos",
    "numVideos",
    "label_photo_only",
    "label_video_only",
    "label_both_content",
  ];

  toggleCheckboxes = () => {
    this.label_both_contentTarget.classList.remove("active");
    this.label_video_onlyTarget.classList.remove("active");
    this.label_photo_onlyTarget.classList.remove("active");
  };

  handleChange = (e) => {
    // console.log(e.currentTarget);
    this.toggleCheckboxes();
    if (e.target.value === "both_content") {
      this.label_both_contentTarget.classList.add("active");
      this.vidLengthTarget.style.display = "block";
      this.numPhotosTarget.style.display = "block";
      this.numVideosTarget.style.display = "block";
    } else if (e.target.value === "video_only") {
      this.label_video_onlyTarget.classList.add("active");
      this.vidLengthTarget.style.display = "block";
      this.numPhotosTarget.style.display = "none";
      this.numVideosTarget.style.display = "block";
    } else if (e.target.value === "photo_only") {
      this.label_photo_onlyTarget.classList.add("active");
      this.vidLengthTarget.style.display = "none";
      this.numPhotosTarget.style.display = "block";
      this.numVideosTarget.style.display = "none";
    }
  };
}
