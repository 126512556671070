import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["rate", "total", "qty"];

  connect() {
    this.element[this.identifier] = this;
  }

  toggle(event) {
    const isChecked = event.currentTarget.checked;

    this.totalTarget.value = isChecked ? this.rateTarget.value : 0;
    isChecked
      ? this.rateTarget.removeAttribute("readonly")
      : this.rateTarget.setAttribute("readonly", null);
  }

  handleChange() {
    this.totalTarget.value = this.rateTarget.value;
  }

  changeHour(newHour) {
    this.data.set("totalhours", newHour);
    this.handleProduct();
  }

  handleProduct(event) {
    const qty = +this.qtyTarget.value;
    const rate = +this.rateTarget.value;

    let factor = 1;

    // TODO: hackish, but it works
    if (event) {
      factor = +event.currentTarget.dataset.factor || 1;
    }

    let total = (qty / factor) * rate;

    if (this.data.has("totalhours")) {
      const totalHours = +this.data.get("totalhours");
      total *= totalHours;
      totalHours === 0
        ? this.qtyTarget.setAttribute("readonly", null)
        : this.qtyTarget.removeAttribute("readonly");
    }

    this.totalTarget.value = Math.round(total * 10) / 10;
  }
}
