const classToggle = (labels) => {
  labels.forEach((label) => {
    label.addEventListener("click", (e) => {
      e.currentTarget.classList.toggle("active");
    });
  });
};

const toggleInput = (checkBox) => {
  checkBox?.addEventListener("click", (e) => {
    const displayForm = e.currentTarget.classList.contains("active")
      ? "block"
      : "none";
    e.currentTarget.nextElementSibling.style.display = displayForm;
    if (displayForm == "none"){
      e.currentTarget.nextElementSibling.value = '';
    }
  });
};



const initDeliverableForm = () => {
  const requirementsLabels = document.querySelectorAll(".deliverable-usage label");
  const extraUsage = document.querySelector("#extraUsage");
  classToggle(requirementsLabels)
  toggleInput(extraUsage)
};

window.initDeliverableForm = initDeliverableForm
export { initDeliverableForm };
