import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["totalPrice", "deposit", "form"];

  update() {
    const form = this.formTarget;
    const data = new FormData(form);

    const totalPrice = this.calculate(data);
    this.totalPriceTarget.value = totalPrice;
    this.depositTarget.value = totalPrice / 2;
  }

  isBreakdownElements(str) {
    return (
      str.includes("campaign") &&
      !str.includes("price") &&
      !str.includes("deposit")
    );
  }

  calculate(data) {
    let totalCost = 0;

    for (let [key, val] of data.entries()) {
      if (this.isBreakdownElements(key)) {
        totalCost += parseFloat(val);
      }
    }

    return totalCost;
  }
}
