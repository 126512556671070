import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["pay", "data"];

	pay() {
		const { checkoutSessionId, publishableKey } = this.dataTarget.dataset;
		// eslint-disable-next-line no-undef
		const stripe = Stripe(publishableKey);
		stripe.redirectToCheckout({
			sessionId: checkoutSessionId
		});
	}
}
