import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'started' ];

  scrollDown(event) {
    event.preventDefault();
    this.startedTarget.scrollIntoView({behavior: "smooth"});
  }
}
